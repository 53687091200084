// import React,{Component} from "react";
// import  ReactDOM  from "react-dom/client"
// import Statemanagement from "./class Component/Statemanagement";
// const root=ReactDOM.createRoot(document.getElementById("root"))
// root.render(
//     <>
//         <Statemanagement />
//     </>
// ) 
// import React,{Component} from "react";
// import  ReactDOM  from "react-dom/client"
// import Parent from "./class Component/Parent";

// const root=ReactDOM.createRoot(document.getElementById("root"))
// root.render(
//     <>
//         <Parent />
//     </>
// )    
// import React,{Component} from "react";
// import  ReactDOM  from "react-dom/client"
// import CSS from "./class componentF/CSS";


// const root=ReactDOM.createRoot(document.getElementById("root"))
// root.render(
//     <>
//         <CSS />
//     </>
// )   
// import React from "react";
// import  ReactDOM  from "react-dom/client"
// import images from "./class componentF/images";
// const root=ReactDOM.createRoot(document.getElementById("root"))
// root.render(
//     <>
//       <images/>
//     </>
// )   
// import React from "react";
// import  ReactDOM  from "react-dom/client"
// import { Link } from "react-router-dom";
// import App from "./FunctionComponents/Routingexample/App";
// const root=ReactDOM.createRoot(document.getElementById("root"))
// root.render(
//     <>
//       <App/>
//     </>
// )  
// import React from "react";
// import  ReactDOM  from "react-dom/client"
// import { Link } from "react-router-dom";
// import Bootstrap from "./FunctionComponents/Bootstrap";

// const root=ReactDOM.createRoot(document.getElementById("root"))
// root.render(
//     <>
//       <Bootstrap/>
//     </>
// )  
import React from "react";
import  ReactDOM  from "react-dom/client"
import { Link } from "react-router-dom";
import Bootstrap from "./FunctionComponents/Bootstrap";



const root=ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <>
      <Bootstrap/>
    </>
)  








// import React from "react";
// import  ReactDOM  from "react-dom/client"
// const root=ReactDOM.createRoot(document.getElementById("root"))
// root.render(
//     <>
//     <h1>Nadeem Saddieq</h1>,
// <h2>This is first react program</h2>
// </>
//)
//  import React from "react";
//  import  ReactDOM  from "react-dom/client"
//  const root=ReactDOM.createRoot(document.getElementById("root"))
//  root.render(
//  [<h1>Nadeem Saddieq</h1>,
//  <h2>This is first react program</h2>]
//  )